export const environment = {
  isReleaseBuild: false,
  envName: 'Project Three',
  isDev: true,
  enableDebug: false,
  useCache: false,
  useProxy: true,
  baseApiUrl: '/Services/MessengerProjThree/',
  host: 'https://webservicesmo.energytransfer.com',
  port: ':443',
  secondaryUrl: 'https://webservicesmo.energytransfer.com',
  infoPostBaseUrl: 'http://mo.peplmessenger.energytransfer.com/mo/ipost',
  extSecurityUrl: 'https://mo.security.energytransfer.net/'
};
